<template>
  <div>
    <b-card class="mb-2">
      <b-input-group class="mb-1">
        <b-input-group-prepend>
          <b-button variant="primary" @click="list()" :disabled="busy.list">
            <i class="fa fa-search"></i> 검색
            <b-spinner class="ml-1" small v-if="busy.list"></b-spinner>
          </b-button>
        </b-input-group-prepend>
        <b-form-input type="text" placeholder="" v-model="form.list.search" @keypress.enter="list()" v-focus></b-form-input>
      </b-input-group>

      <form-options v-model="form.list" v-bind="{formOptions}">
        <template v-slot:slotEnd>
          <div class="flex-grow-0 mb-1 mr-2">
            <small>Color 선택</small><br/>
            <color-checkbox v-model="form.list.major_color"></color-checkbox>
          </div>
          <div class="flex-grow-0 mb-1 mr-2">
            <small>기본 상품수</small><br/>
            <b-form-input class="text-center w-70px" size="sm" title="한 번에 가져올 상품 수" v-model.number="form.list.limit" @keypress.enter="list()"></b-form-input>
          </div>
        </template>
      </form-options>

      <b-collapse id="collapse1" v-model="collapse.detail">
        <hr/>
        <b-row>
          <b-col class="mb-1" cols="12" md="12" xl="4">
            <template v-if="$R(['BALAANEER', 'E_GOODS_R']) || $S.user.group.includes('operator')">
              <shop-preset v-model="form.list.shop"></shop-preset>
            </template>
          </b-col>
          <b-col class="mb-1" cols="12" md="6" xl="4">
            <brand-preset class="" v-model="form.list.brand" :hideDisabled="true"></brand-preset>
          </b-col>
          <b-col class="mb-1" cols="12" md="6" xl="4">
            <category-preset class="" v-model="form.list.category"></category-preset>
          </b-col>
        </b-row>

        <div class="fs-12 bold">
          검색 필드
        </div>
        <form-fields ref="fields" v-model="form.list.fields" :name="$options.name" :customFormFields.sync="customFormFields"
                     v-bind="{formFields, defaultFields}" @enter="list()"></form-fields>

        <b-row class="mb-3">
          <b-col cols="12" md="6" xl="3">
            <small>발란코드</small>
            <div class="flex-row flex-wrap d-flex">
              <b-form-textarea class="flex-grow-0 w-50" :rows="2" v-model="form.list.goods_no_include" placeholder="포함할 발란코드"></b-form-textarea>
              <b-form-textarea class="flex-grow-0 w-50" :rows="2" v-model="form.list.goods_no_exclude" placeholder="제외할 발란코드"></b-form-textarea>
            </div>
          </b-col>
          <b-col cols="12" md="6" xl="3">
            <small>goods_id</small>
            <div class="flex-row flex-wrap d-flex">
              <b-form-textarea class="w-50" :rows="2" v-model="form.list.goods_id_include" placeholder="포함할 goods_id"></b-form-textarea>
              <b-form-textarea class="w-50" :rows="2" v-model="form.list.goods_id_exclude" placeholder="제외할 goods_id"></b-form-textarea>
            </div>
          </b-col>
          <b-col cols="12" md="6" xl="3">
            <small>gid</small>
            <div class="flex-row flex-wrap d-flex">
              <b-form-textarea class="w-50" :rows="2" v-model="form.list.gid_include" placeholder="포함할 gid"></b-form-textarea>
              <b-form-textarea class="w-50" :rows="2" v-model="form.list.gid_exclude" placeholder="제외할 gid"></b-form-textarea>
            </div>
          </b-col>
          <b-col cols="12" md="6" xl="3">
            <small>sku</small>
            <div class="flex-row flex-wrap d-flex">
              <b-form-textarea class="w-50" :rows="2" v-model="form.list.sku_include" placeholder="포함할 SKU(파트너 SKU 및 통합 SKU 대상)"></b-form-textarea>
              <b-form-textarea class="w-50" :rows="2" v-model="form.list.sku_exclude" placeholder="제외할 SKU"></b-form-textarea>
            </div>
          </b-col>
        </b-row>
      </b-collapse>

      <div class="mt-2">
        <b-button class="mr-1" variant="primary" @click="list()" :disabled="busy.list">검색<b-spinner class="ml-1" small v-if="busy.list"></b-spinner></b-button>
        <b-button class="mr-1" variant="warning" @click="resetForm">초기화</b-button>
        <b-button class="mr-1" variant="outline-success" v-b-toggle.collapse1>상세검색조건</b-button>
      </div>
    </b-card>

    <confirmed-modal ref="confirmedModal" v-bind="{modal, momentBiz, shopMap, targetMap}"></confirmed-modal>

    <div class="clearfix">
      <div class="pull-right">
        <b-dropdown right variant="light" class="m-1">
          <template #button-content>
            <i class="fa fa-copy"></i>
          </template>
          <b-dropdown-item @click="copy('goods_no')">발란코드</b-dropdown-item>
          <b-dropdown-item @click="copy('goods_id')">Goods ID</b-dropdown-item>
          <b-dropdown-item @click="copy('goods_id', {withQuotes: true})">Goods ID(따옴표)</b-dropdown-item>
        </b-dropdown>
      </div>
    </div>

    <c-table ref="c-table:list" v-if="itemMode === 'list'" :table-data="items.list" :fields="fields.list" :perPage.sync="perPage"
             :isBusy="busy.list" :getMoreBusy="busy.listmore" :hasMore="hasMore.list"
             :caption="`현재 ${items.list.length}${hasMore.list && !total.list ? '+' : ''} 개` +
              `${total.list ? ` (총 ${total.list.value.comma()}${total.list.relation === 'gte' ? '+' : ''} 개)` : ''}`"
             @btn-clicked="btnAction" @get-more="list(true)"></c-table>

  </div>
</template>

<script>
import Vue from 'vue'
import confirmedModal from '@/views/goods/ConfirmedModal.vue'
import {getHost} from '@/shared/api'
import {MALL_GOODS_LINK, ORDER_STATUS_CODE, CONFIRMED_COLUMNS, GODO_COL_TARGET, CF_TO_BL_GOODS_ADDON} from 'balaan_constants'
import * as momentBiz from 'moment-business-days';
import FormFields from "../modules/FormFields";
import FormOptions from "../modules/FormOptions";
import ColorCheckbox from "../modules/ColorCheckbox";
import {formOptionsPreset} from "@/shared/fields";

const targetMap = { // 상품링크 매칭에 사용됨
  godo: '고도몰',
  lotteimall: '롯데아이몰',
  smartstore: '스토어팜(발란)',
};

const onedayDeliveryLimit = '14:00:59';
const onedayDeliveryLimitNext = '13:00:59';

export default {
  name: 'ConfirmedES',
  title: '등록상품검색',
  components: {FormFields, FormOptions, ColorCheckbox, confirmedModal},
  data() {
    return {
      getHost,
      MALL_GOODS_LINK, ORDER_STATUS_CODE, CONFIRMED_COLUMNS, GODO_COL_TARGET, CF_TO_BL_GOODS_ADDON, momentBiz, targetMap,
      shop: [],
      shopMap: {},
      brand: [],
      brandMap: {},
      category: [],
      categoryMap: {},
      godoCols: [],
      selectedGodoCols: [],
      syncForce: false,
      defaultForm: {
        list: {
          search: '',
          shop: [],
          brand: [],
          category: [],

          goods_status: 'registered',
          stock_status: 'ALL',
          display_status: 'ALL',
          gm_id_exists: 'ALL',
          manual: 'ALL',

          delivery_type: 'ALL',
          oneday_delivery: 'ALL',
          today_pick: 'ALL',
          store_pickup: 'ALL',
          gift_packing: 'ALL',
          goods_nm_en_exists: 'ALL',
          hide_es: 'ALL',

          sortKey: 'goods_no',
          sortDir: 'desc',

          limit: 100,
          skip: 0,

          fields: {},

          major_color: this.$C.COLORS.map(e => e.name),

          goods_no_include: '',
          goods_no_exclude: '',
          goods_id_include: '',
          goods_id_exclude: '',
          gid_include: '',
          gid_exclude: '',
          sku_include: '',
          sku_exclude: '',
        }
      },
      form: {
        list: {},
      },
      collapse: {detail: true},
      lastBody: {list: {}},
      items: {list: []},
      total: {list: null}, // list: {value: 0, relation: 'eq'}
      busy: {list: false, listmore: false},
      hasMore: {list: false},
      ac: {list: null}, // abortController

      title: '',
      // item: {},
      itemAttr: {},
      imageIntent: 'default',
      fields: {
        list: [
          'selected',
          {key: '_img60', label: '이미지', class: 'text-center'},
          {
            key: 'html2',
            label: `상품정보<br/><span class="badge badge-success">판매 가능수량</span><span class="badge badge-primary">SHOP 원본 수량</span>`
              + `<span class="badge badge-info">결제된 주문수량</span><span class="badge badge-warning">10분이내 주문 미결제수량</span>`,
            style: {minWidth: '250px'}
          },
          {
            key: 'html5',
            label: '<span class="badge badge-light">goods_id</span><br/><span class="badge badge-light">SKU ID</span><br/>'
              + '<span class="badge badge-light">통합 SKU</span>',
            class: 'text-center'
          },
          {
            key: 'html8',
            label: '<span class="badge alert-success">시즌</span><br/>' +
              '<span class="badge alert-light">컬러</span><br/>' +
              '<span class="badge alert-warning">원산지</span>',
            class: 'text-center'
          },
          {
            key: 'html1',
            label: '<span class="badge badge-light">연동형태</span><br/><span class="badge alert-success">상품유형</span><br/>'
              + '<span class="badge badge-light">중고등급</span>',
            class: 'text-center'
          },
          {key: 'html10', label: '배송정보', class: 'text-center', style: {minWidth: '105px'}},
          // {key: 'html4', label: '외부몰', class: 'text-center', style: {minWidth: '60px'}},
          {key: 'html3', label: '가격', class: 'text-right'},
          // {key: 'html9', label:'발란몰가<br/>SS가', class: 'text-right'},
          {key: 'html6', label: '재고', style: {width: '50px'}, class: 'text-center'},
          {key: 'html7', label: '상태', class: 'text-center'},
          {key: '_actions', label: '상세', style: {width: '55px', textAlign: 'center'}, buttons: [{label: '상세', event: 'show_modal'}]},
        ]
      },
      perPage: 20,
      itemMode: 'list',
      modal: {detail: false, displayStatus: false, diffList: false, image: false, field: false, godo: false},
      editMode: false,
      diff: null,
      priceHtml: '',
      displayStatus: 'view',
      displayStatusRelay: true,
      displayStatusReason: '',
      today: momentBiz().format('YYYY-MM-DD'),
      formOptions: [
        [
          {
            name: '프리셋', key: 'preset', options: [
              {
                text: '전체', click: () => {
                  this.form.list.goods_status = 'ALL';
                  this.form.list.stock_status = 'ALL';
                  this.form.list.display_status = 'ALL';
                }, variant: 'outline-dark'
              },
              {
                text: '등록상품', click: () => {
                  this.form.list.goods_status = 'registered';
                  this.form.list.stock_status = 'ALL';
                  this.form.list.display_status = 'ALL';
                }, variant: 'outline-info'
              },
              {
                text: 'Live', click: () => {
                  this.form.list.goods_status = 'registered';
                  this.form.list.stock_status = 'normal';
                  this.form.list.display_status = 'view';
                }, variant: 'outline-primary'
              },
              {
                text: '미노출', click: () => {
                  this.form.list.goods_status = 'ALL';
                  this.form.list.stock_status = 'ALL';
                  this.form.list.display_status = 'notview';
                }, variant: 'outline-warning'
              },
            ]
          },
          {
            name: '등록상태', key: 'goods_status', options: [
              {text: '전체', value: 'ALL'},
              {text: 'Processing', value: 'processing', variant: 'info'},
              {text: 'Registered', value: 'registered', variant: 'success'},
              {text: 'Terminated', value: 'terminated', variant: 'danger'}
            ]
          },
          {
            name: '재고상태', key: 'stock_status', options: [
              {text: '전체', value: 'ALL'},
              {text: '재고있음', value: 'normal', variant: 'success'},
              {text: '품절', value: 'runout', variant: 'warning'}
            ]
          },
          {
            name: '노출상태', key: 'display_status', options: [
              {text: '전체', value: 'ALL'},
              {text: '노출', value: 'view', variant: 'success'},
              {text: '미노출', value: 'notview', variant: 'warning'}
            ]
          },
          {
            name: '카탈로그(GM)', key: 'gm_id_exists', options: [
              {text: '전체', value: 'ALL'},
              {text: '매칭됨', value: 'y', variant: 'success'},
              {text: '매칭안됨', value: 'n', variant: 'warning'},
            ]
          },
          {
            name: '연동형태', key: 'manual', options: [
              {text: '전체', value: 'ALL'},
              {text: '자동(FEED)', value: 'auto', variant: 'info'},
              {text: '파트너관리', value: 'manual', variant: 'success'}
            ]
          },
          {
            name: '배송유형', key: 'delivery_type', options: [
              {text: '전체', value: 'ALL'},
              {text: '해외', value: '해외', variant: 'success'},
              {text: '국내', value: '국내', variant: 'primary'},
            ]
          },
          {name: '오늘도착', key: 'oneday_delivery', options: formOptionsPreset.AVAIL_Y},
          {name: '오늘출발', key: 'today_pick', options: formOptionsPreset.AVAIL_Y},
          {name: '매장픽업', key: 'store_pickup', options: formOptionsPreset.AVAIL_Y},
          {name: '선물포장', key: 'gift_packing', options: formOptionsPreset.AVAIL_Y},
          {name: '영문이름 여부', key: 'goods_nm_en_exists', options: formOptionsPreset.EXISTS_YN},
          {
            name: '검색엔진', key: 'hide_es', options: [
              {text: '전체', value: 'ALL'},
              {text: '포함', value: 'n', variant: 'success'},
              {text: '제외', value: 'y', variant: 'warning'},
            ]
          },
          // {key: 'divider'},
          {
            name: '정렬기준', key: 'sortKey', options: [
              {text: '발란코드', value: 'goods_no', variant: 'primary'},
              {text: '등록일시', value: 'registered_dt', variant: 'info'},
              {text: '가격', value: 'price', variant: 'info'},
              {text: '소비자가', value: 'consumer', variant: 'info'},
              {text: '할인율', value: 'discount_rate', variant: 'info'},
              {text: '재고', value: 'tot_stock', variant: 'info'},
              {text: '시즌', value: 'season', variant: 'info'},
              {text: '옵션수', value: 'optionCount', variant: 'info'},
              {text: '재고있는옵션수', value: 'optionHasStockCount', variant: 'info'},
              {text: '오늘출발시각', value: 'today_pick_time', variant: 'info'},
            ]
          },
          {name: '정렬방향', key: 'sortDir', options: formOptionsPreset.SORT_DIR},
        ],
      ],
      formFields: [
        {
          name: '판매가', key: 'price', type: 'number', rangePreset: [
            {text: '~ 1만원', gte: '', lte: 10000},
            {text: '1 ~ 3만원', gte: 10000, lte: 30000},
            {text: '3 ~ 10만원', gte: 30000, lte: 100000},
            {text: '10 ~ 20만원', gte: 100000, lte: 200000},
            {text: '20 ~ 30만원', gte: 200000, lte: 300000},
            {text: '30 ~ 50만원', gte: 300000, lte: 500000},
            {text: '50 ~ 100만원', gte: 500000, lte: 1000000},
            {text: '100 ~ 300만원', gte: 1000000, lte: 3000000},
            {text: '300 ~ 500만원', gte: 3000000, lte: 5000000},
            {text: '500 ~ 1000만원', gte: 5000000, lte: 10000000},
            {text: '1000만원 ~', gte: 10000000, lte: ''},
          ]
        },
        {name: '소비자가', key: 'consumer', type: 'number'},
        {name: '발란코드', key: 'goods_no', type: 'number'},
        {name: 'Sku ID', key: 'sku_id', type: 'string', width: 150},
        {name: '통합 Sku', key: 'matched_sku_id', type: 'string', width: 150},
        {name: 'Short Sku', key: 'short_sku', type: 'string', width: 150},
        {name: 'B최저가 순위', key: 'b_rank', type: 'number', width: 110},
        {name: 'B최저가 차이(%)', key: 'b_price_diff_per', type: 'number', op: 'range', width: 130},
        {name: '시즌', key: 'season', type: 'string', width: 120},
        {name: '원산지', key: 'origin', type: 'string', width: 120},
        {name: '총 재고', key: 'tot_stock', type: 'number', op: 'range', width: 100},
        {name: '옵션 재고', key: 'options.stock', type: 'number', op: 'range'},
        {name: '옵션 Size', key: 'options.Size', type: 'string', op: 'like', width: 130},
        {name: '옵션수', key: 'optionCount', type: 'number', op: 'range'},
        {name: '재고있는옵션수', key: 'optionHasStockCount', type: 'number', op: 'range', width: 130},
        {name: '상품관리코드', key: 'mng_code', type: 'string', width: 150},
        {name: '오늘출발시각', key: 'today_pick_time', type: 'string', placeholder: '13:00', disableLike: true},
        {name: '생성시각', key: '_cdt', type: 'string', placeholder: '2020-01-01 01:02:03', disableLike: true, width: 155},
        {name: '등록시각', key: 'registered_dt', type: 'string', placeholder: '2020-01-01 01:02:03', disableLike: true, width: 155},
      ],
      defaultFields: 'price:range,sku_id:eq,matched_sku_id:eq,b_rank:eq,season:eq,options.Size:like',
      customFormFields: [],
    }
  },
  sockets: {
    confirmed_query(q) {
      console.log('confirmed_query:', q);
    }
  },
  async created() {
    // form 의 구조가 복잡해져서 최초 초기화와 reset 을 분리
    Vue.set(this.form, 'list', this.$utils.clone(this.defaultForm.list));

    // this.$refs.fields.initFormFields(); // formFields component 의 created 에서 진행
    this.$utils.getStatus(this.$options.name, this, 'itemMode,picWidth,perPage');

    let meta = await this.$api.getMeta('shop,brand,category,holiday');

    meta.shop.forEach(s => {
      s.value = s.boutique;
      s.label = `${s.use_yn !== 'y' ? '[미사용] ' : ''}${s.shop_id}. ${s.boutique}`;
      this.shopMap[s.shop_id] = s;
    }); // use_yn 무관 일단 정보는 필요
    this.shop = meta.shop.filter(e => e.use_yn === 'y').sort((a, b) => a.shop_id - b.shop_id);

    this.brand = meta.brand.map(e => {
      return this.brandMap[e.brand_no] = {...e, value: e.brand_no, label: `${e.brand_nm} (${e.brand_nm_kr})`};
    }).sort((a, b) => a.label.localeCompare(b.label));

    this.category = meta.category.map(e => {
      return this.categoryMap[e.category] = {...e, value: e.category, label: `${e.category} (${e.category_nm})`};
    }).sort((a, b) => (a.value.length - b.value.length) * 10 + a.value.localeCompare(b.value));

    if (Object.keys(this.$route.query).length) {
      Object.keys(this.$route.query).forEach(k => {
        if (this.$route.query[k] != null) {
          let v = this.$route.query[k];
          if (~'tot_stock,opt_stock,min,max,goods_no_from,goods_no_to,naver_rank_from,naver_rank_to,naver_other_cnt,naver_price_diff_per_from,naver_price_diff_per_to,limit,skip'.split(',').indexOf(k)) {
            this.form.list[k] = +v;
          } else if (~'real_shop,has_naver,naver_has_other_cnt,minimal,godo'.split(',').indexOf(k)) {
            this.form.list[k] = !(!v || v === 'false');
          } else if (k === '$shop') {
            let arr = v.split(',');
            this.form.list.shop = arr.map(e => this.shopMap[e]);
          } else if (k === '$category') {
            let arr = v.split(',');
            this.form.list.category = arr.map(e => this.categoryMap[e]);
          } else if (k === '$brand') {
            let arr = v.split(',');
            this.form.list.brand = arr.map(e => this.brandMap[e]);
          } else if (k === 'collapse.detail') {
            this.collapse.detail = !(!v || v === 'false');
          } else if (k === 'itemMode') {
            this.itemMode = v;
          } else if (~Object.keys(this.form.list).indexOf(k)) {
            this.form.list[k] = v;
          }
        }
      });
    }

    // 발란몰로 보낼 수 있는 컬럼 정리
    Object.keys(GODO_COL_TARGET).forEach(k => {
      let col = CONFIRMED_COLUMNS[k];
      if (col) {
        this.godoCols.push({value: k, text: col.name, desc: col.desc});
      }
    });
    Object.keys(CF_TO_BL_GOODS_ADDON).filter(k => k !== 'goods_no').forEach(k => {
      let col = CONFIRMED_COLUMNS[k];
      if (col) {
        this.godoCols.push({value: k, text: col.name, desc: col.desc});
      }
    });

    const no = this.$route.params.no;
    if (no) {
      this.form.list.search = no;
      this.form.list.display_status = 'ALL';
      await this.list();
      let item = this.items.list.filter(e => e.goods_no === +no)[0];
      if (item) this.showModal({item});
      return;
    }
    this.list();
  },
  async beforeDestroy() {
    Object.values(this.ac).filter(e => e).forEach(e => e.abort());
    this.$utils.setStatus(this.$options.name, this, 'itemMode,picWidth,perPage');
    this.$refs.fields.saveFormFields();
  },
  watch: {
    perPage() {
      this.$utils.setStatus(this.$options.name, this, 'itemMode,picWidth,perPage');
    }
  },
  methods: {
    godoPriceLambda(price) {
      return price;
    },
    ssPriceLambda(e) {
      return e;
    },
    calcIncExc(inc, exc) {
      let include = inc ? inc.trim().split(/\r?\n/g).map(e => e.trim()) : [];
      let exclude = exc ? exc.trim().split(/\r?\n/g).map(e => e.trim()) : [];
      if (include.length && exclude.length) { // 둘 다 존재시 exclude 를 include 에서 제외
        const excludeMap = this.$utils.arr2map(exclude);
        include = include.filter(e => !excludeMap[e]);
        exclude = [];
      }
      return [include, exclude];
    },
    async list(more) {
      // const countCheckFields = 'goods_no_include,goods_no_exclude,goods_id_include,goods_id_exclude,gid_include,gid_exclude,sku_include,sku_exclude'.split(',');
      // if (countCheckFields.some(f => this.form.list[f].trim().split(/\r?\n/).length > 65000)) {
      //   return alert('포함/제외 에 입력되는 값들은 65000 개 이하로 넣어주세요');
      // }
      const form = this.form.list;

      const shop = form.shop.length === this.shop.length ? [] : form.shop.map(e => e.shop_id); // 전체 선택일 경우 비우기
      const brand = form.brand.map(e => e.value);
      const category = form.category.map(e => e.value);
      const major_color = form.major_color.length === this.$C.COLORS.length ? [] : form.major_color; // 전체 선택일 경우 비우기
      const fields = this.$refs.fields && this.$refs.fields.makeFieldsQuery() || [];

      let [goods_no_include, goods_no_exclude] = this.calcIncExc(form.goods_no_include, form.goods_no_exclude);
      goods_no_include = goods_no_include.map(e => e.split(/\D+/g)).flat().map(e => +e); // 123,134 로 한 줄에 들어오는 숫자 특별처리
      goods_no_exclude = goods_no_exclude.map(e => e.split(/\D+/g)).flat().map(e => +e); // 123,134 로 한 줄에 들어오는 숫자 특별처리
      const [goods_id_include, goods_id_exclude] = this.calcIncExc(form.goods_id_include, form.goods_id_exclude);
      const [gid_include, gid_exclude] = this.calcIncExc(form.gid_include, form.gid_exclude);
      const [sku_include, sku_exclude] = this.calcIncExc(form.sku_include, form.sku_exclude);

      const body = {
        ...form,
        shop,
        brand,
        category,
        major_color,
        fields,
        goods_no_include,
        goods_no_exclude,
        goods_id_include,
        goods_id_exclude,
        gid_include,
        gid_exclude,
        sku_include,
        sku_exclude
      };
      const j = await this.$api.postTable(this, '/goods/confirmed/es', body, {more, fnAssign: this.assignTableData, uniqueSortKeys: ['goods_no']});
      // 검색결과중 검색필드와 일치하는 데이터가 있다면 url 을 바꿔준다.
      if (j && j.list.filter(e => e.goods_no + '' === form.search).length && location.hash === '#/goods') {
        history.replaceState(null, null, location.origin + '/#/goods/' + form.search);
      }
    },
    assignTableData(e) {
      const styleEllipsis = 'white-space: nowrap; overflow: hidden; text-overflow: ellipsis; display: inline-block;';
      if (e.category) {
        let cates = [];
        for (let i = 3; i <= e.category.length; i += 3) {
          if (e.category.substring(i - 3, i) === '001') cates.push('전체');
          else cates.push(this.categoryMap[e.category.substring(0, i)] && this.categoryMap[e.category.substring(0, i)].category_nm || '?');
        }
        e.cate = cates.join(' > ');
      } else if (e.brand_no !== 0) {
        // 개인결제창이 아닌 경우
        console.log('카테고리 없음 : ', e);
      }
      if (!e.consumer) e.consumer = e.price;

      // 배송유형 및 일자, 구매대행 판단
      let shop = this.shopMap[e.shop_id];
      if (shop) {
        if (shop.delivery_type !== 'both') {
          e.delivery_type = {abroad: '해외', domestic: '국내'}[shop.delivery_type];
          e.delivery_day = shop.delivery_day;
        } else {
          if (e.delivery_type === '국내') {
            e.delivery_day = shop.delivery_day;
          } else {
            e.delivery_day = shop.delivery_day_abroad || 21;
          }
        }
        e.delivery_str = e.delivery_type + (shop.logistics === 'direct' ? (e.delivery_type === '해외' && shop.pccc_feed === 'y' ? '구매대행' : '직배송') : '');
      } else {
        e.delivery_str = 'SHOP배송정보없음';
        e.delivery_day = 21; // 이전부터 있던 하드코딩, 6일인 이유는?
      }
      e.godoPrice = this.godoPriceLambda(e.price, {category: e.category});
      e.ssPrice = this.ssPriceLambda(e.price);

      e.img = e.images && e.images[0] ? `https://i.balaan.io/${e.image_path}${e.images[0].thumb_200}` : e.img_urls[0];
      // e.html1 = `<a href="${this.MALL_URL}/shop/goods/goods_view.php?goodsno=${e.goods_no}" target="_blank">${e.goods_no}</a><br/>`
      //   + `<span class="badge badge-light">${e.category}</span>`;

      const optionsHtml = `${e.options.filter(e => !e.not_found).map(e =>
        `<span class="badge badge-light">${e.Size}</span><span class="badge badge-${e.stock > 0 ? 'success' : 'secondary'}" title="판매 가능수량">${e.stock}</span>`
        + (e.shop_stock != null && e.shop_stock !== e.stock ? `<span class="badge badge-${e.shop_stock > 0 ? 'primary' : 'secondary'}" title="SHOP 의 원본 수량">${e.shop_stock}</span>` : '')
        + (e.order_stock ? `<span class="badge badge-info" title="결제된 주문수량">${e.order_stock}</span>` : '')
        + (e.prepared_stock ? `<span class="badge badge-warning" title="결제 전 주문수량">${e.prepared_stock}</span>` : '')).join(', ')}`;

      e.html2 = `<a href="/#/shop/${e.shop_id}" target="_blank" class="badge badge-success">${e.shop_id}. ${e.shop} <i class="fa fa-external-link"></i></a> <span class="badge badge-warning">${e.brand_nm}</span>`
        + ` <span class="badge badge-light" title="${e.category}">${e.cate || ''}</span> <a href="${this.MALL_URL}/shop/goods/goods_view.php?goodsno=${e.goods_no}" class="badge badge-info" target="_blank">${e.goods_no} <i class="fa fa-external-link"></i></a>`
        + `<br/>`
        + `${e.goods_nm}<br/><div style="display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical; overflow: hidden;">`
        + `<small><b>${e.options && e.options.length ? e.options[0].optnm : ''}</b></small> `
        + optionsHtml + '</div>';
      e.html3 = (e.consumer === e.price ? '' : `<del>${e.consumer ? this.$utils.comma(e.consumer) : ''}</del><br/>`)
        + `${e.price ? this.$utils.comma(e.price) : ''}<br/>` + (e.consumer === e.price ? '' : `(${100 - Math.round(e.price / e.consumer * 100)}%)`);
      e.html6 = e.tot_stock;
      // e.html4 = e.links.map(s=>`<span class="badge badge-primary">${s.mall}</span>&nbsp;`
      //   + (targetMap[s.mall] ? `<a href="${MALL_GOODS_LINK[targetMap[s.mall]]}${s.mall_id}" target="_blank" class="badge badge-info" title="${s._cdt}">${s.mall_id} <i class="fa fa-external-link"></i></a>` : `<span class="badge badge-secondary">${s.mall_id}</span>`)
      // ).join('<br/>');
      e.html9 = `${this.$utils.rnc(e.godoPrice)}<br/>${this.$utils.rnc(e.ssPrice)}`;
      e.html5 = (e.crawl_url && e.crawl_url.startsWith('http') ? `<a href="${`https://hub.balaan.io/r?u=${encodeURIComponent(e.crawl_url)}`}" target="_blank" class="badge badge-info">${e.goods_id} <i class="fa fa-external-link"></i></a>` : `<span class="badge badge-light">${e.goods_id}</span>`) + '<br/>'
        + (e.sku_id ? `<a href="https://search.shopping.naver.com/search/all.nhn?where=all&frm=NVSCTAB&query=${encodeURIComponent(e.sku_id)}"` +
          ` target="_blank" class="badge badge-success">` +
          `<span style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis; display: inline-block; max-width: 80px;">${e.sku_id}</span>` +
          ` <i class="fa fa-external-link"></i></a>` : '') + `<br/>`
        + (e.matched_sku_id ? `<a href="https://search.shopping.naver.com/search/all.nhn?where=all&frm=NVSCTAB&query=${encodeURIComponent(e.matched_sku_id)}"` +
          ` target="_blank" class="badge badge-success">` +
          `<span style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis; display: inline-block; max-width: 80px;">${e.matched_sku_id}</span>` +
          ` <i class="fa fa-external-link"></i></a>` : '') + `<br/>`
      ;
      e.html1 = (e.manual ? `<span class="badge alert-danger">파트너관리</span>` : '') + '<br/>'
        + (e.goodsType === 'used' ? `<span class="badge alert-warning">빈티지</span><br/><span class="badge badge-light">${e.usedGrade}</span>` :
          '<span class="badge alert-success">새상품</span>')
      ;
      e.html7 = !e.open ? [e.goods_status, e.stock_status, e.display_status].map(e => this.$utils.badge(e)).join('<br/>') :
        [e.goods_status, e.stock_status, e.display_status].map(e => this.$utils.badge(e)).join('<br/>').concat(this.$utils.badge(e.open));
      e.html8 = (e.season ? `<span class="badge alert-success" title="${(e.season || '').escapeHtml()}" style="${styleEllipsis} max-width: 80px;">` +
        `${e.season}</span>` : '') + '<br/>' +
        (e.major_color ? `<span class="badge alert-light" title="${(e.major_color || '').escapeHtml()}" style="${styleEllipsis} max-width: 80px;">` +
          `${e.major_color}</span>` : '') + '<br/>' +
        (e.origin ? `<span class="badge alert-warning" title="${(e.origin || '').escapeHtml()}" style="${styleEllipsis} max-width: 80px;">${e.origin}</span>` : '');

      const odLimit = this.$moment().format('YYYY-MM-DD HH:mm:ss') <= '2022-03-31 ' + onedayDeliveryLimit ? onedayDeliveryLimit : onedayDeliveryLimitNext;
      e._badgeOnedayDelivery = e.oneday_delivery === 'Y' ?
        `<span class="badge alert-danger">오늘도착</span><br/><small>마감시간: ${odLimit.slice(0, 5)}</small><br/>` :
        '';
      e._badgeTodayPick = e.today_pick ?
        `<span class="badge alert-success">오늘출발</span><br/><small>마감시간: ${e.today_pick_time}</small><br/>` :
        '';
      e._badgeOrderMade = e.orderMade ? '<span class="mr-1 badge alert-secondary">주문제작</span>' : '';
      e._badgeGift = e.gift_packing ? '<span class="mr-1 badge badge-danger">포장</span>' : '';
      e._badgeStore = e.store_pickup ? '<span class="badge badge-primary">픽업</span>' : '';

      e.html10 = `<span class="badge alert-${e.delivery_type === '해외' ? 'success' : 'info'}">${e.delivery_str}</span>`
        + `<span class="badge badge-light">${e.delivery_day}일</span><br/>`
        + e._badgeOnedayDelivery + e._badgeTodayPick
        + e._badgeGift + e._badgeStore + (e._badgeGift || e._badgeStore ? '<br/>' : '');
    },
    showModal(row) {
      this.$refs.confirmedModal.showModal(row);
    },

    btnAction(row, event) {
      if (event === 'show_modal') {
        this.showModal(row);
      }
    },
    copy(col, {withQuotes = false} = {}) {
      let selected = this.items.list.filter(e => e.selected);
      if (!selected.length) return alert('복사할 상품을 선택해주세요');
      let res = this.$utils.copyToClipboard(selected.map(e => withQuotes ? `'${e[col].toString().replace(/'/g, "\\'")}'` : e[col]).join(withQuotes ? ',\n' : '\n'));
      if (res) this.$alertTop(`복사되었습니다`);
    },
    resetForm() {
      const fields = this.form.list.fields;
      this.form.list = this.$utils.clone(this.defaultForm.list);
      Vue.set(this.form.list, 'fields', fields);
      this.$refs.fields.resetFieldValues();
    },
  }
}
</script>

<style>
.cf_img img {
  border: 1px solid #eee;
  margin-left: 3px;
}

.cf_desc {word-break: break-word;}
.cf_desc img {
  width: 100%;
  max-width: 300px;
  display: block;
}
.cf_desc .mh-600 {
  max-height: 600px;
  overflow-y: hidden;
}

.mp_img img {
  border: 1px solid #eee;
  margin-left: 3px;
}

.mp_desc {word-break: break-word;}
.mp_desc img {
  width: 100%;
  max-width: 300px;
  display: block;
}
.mp_desc .mh-600 {
  max-height: 600px;
  overflow-y: hidden;
}

</style>
